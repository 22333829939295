<template>
  <div class="d-flex justify-center mb-6 user-page">
    User info here or something
    <v-row class="justify-center">
      <v-col cols="2">
        <v-btn @click="logout">
          Logout
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  methods: {
    logout: function() {
      this.$store.commit("logoutUser");
      this.$store.commit("emptyOrders");
      this.$router.push('/')
    },
  },
};
</script>

<style></style>
